import React, {useContext, useMemo} from "react"
import {serverSideTranslations} from "next-i18next/serverSideTranslations"
import PropTypes from "prop-types"
import NavBar from "../components/navigation/NavBar"
import Footer from "../components/footer/Footer"
import LogoGallery from "../components/home/logoGallery/LogoGallery"
import HomeCatBoxes from "../components/home/homeCatBoxes/HomeCatBoxes"
import HowItWorks from "../components/home/howItWorks/HowItWorks"
import GoogleReviews from "../components/googleReviews/GoogleReviews"
import fetchReviews from "../fetch/FetchReviews"
import fetchPosts from "../fetch/FetchPosts"
import nextI18NextConfig from "../next-i18next.config.js"
import BlogsComponent from "../components/reusable/BlogsComponent"
import {useTranslation} from "next-i18next"
import MbpHead from "../components/head/MbpHead"
import HomeTextComponent from "../components/home/HomeTextComponent"
import BannerHomeSpringCampaign from "../components/home/BannerHomeSpringCampaign"
import {getPageData} from "../pageData"
import DataContext from "../context/DataContext"
import InstagramSection from "../components/home/mybikeplanInstagram/InstagramSection"
import UspSection from "../components/home/UspSection"
import InsuranceByCalingo from "../components/home/InsuranceByCalingo"
import ServiceCenters from "../components/home/serviceCenters/ServiceCenters"
import GoogleRbox from "../components/googleReviews/GoogleRbox"
import FindPerfectBike from "../components/home/FindPerfectBike/FindPerfectBike"
import {useRouter} from "next/router"
import {staticDataHowItWorks} from "../components/home/howItWorks/contentData"
import DownloadableContent from "../components/home/DownloadableContent/DownloadableContent"
import BikeExplorerTabs from "../components/bikesCollection/BikeExplorerTabs"
import SecondaryCarouselHeroBanner from "../components/reusable/SecondaryCarouselHeroBanner"
import BottomStickyBar from "../components/bottomStickybar/BottomStickyBar"
import ShowroomHomepageSection from "../components/home/howItWorks/ShowroomHomepageSection"

type Props = {
	reviews: any,
	posts: any[],
	landingPage: any,
	strapiContent: any[]
	brands: any[]
	allPremiumBrandsGoogleReviews: any[]
	homeBrands: any[]
}

const Home: React.FC<Props> = ({allPremiumBrandsGoogleReviews, posts, strapiContent, homeBrands}) => {
	const {t} = useTranslation()
	const title = t("E-Bike Auf Raten | 0% Zins | ab Fr. 52 im Monat | Schweiz")
	const description = t("Kaufe ein E-Bike mit einer 0% Zins Finanzierung ab CHF 52 im Monat. Jetzt bestellen und stressfrei über 12-48 Monatsraten bezahlen.")
	const allReviews = useMemo(() => fetchReviews(), [])
	const reviews = {...allReviews, reviews: allPremiumBrandsGoogleReviews.concat(allReviews.reviews)}
	// Temporary keep the contentful data until fully migrate to Strapi
	const {organizationSlug} = useContext(DataContext)
	const organization = organizationSlug ? organizationSlug : null
	const router = useRouter()
	const {locale} = router
	// Get Strapi Data serverside in case of no organization page, otherwise use the Hook useFetchStrapi
	const strapiContentData: any = organization ? strapiContent : strapiContent

	const {data, loading} = strapiContentData
	const homepageContent = loading ? {} : data?.homepage?.attributes
	// Destructure the homepage content from the Strapi Data | hardcoded data
	const {headers, brandImages} = homepageContent
	// Hardcoded data below
	const dataHowItWorks = staticDataHowItWorks[locale]

	return (
		<>
			<MbpHead>
				<title>
					{title}
				</title>
				<meta name="description"
					content={description}/>
				<meta property="og:image"
					content={"/assets/images/favicon.png"}/>
				<meta property="og:title"
					content={title}/>
				<meta property="og:description"
					content={description}/>
			</MbpHead>
			<div id={"landing-page"}>
				<NavBar absolute={false}/>
				{/* <ModalTrigger ModalComponent={BottomStickyBar}/> */}
				<BottomStickyBar/>
				<div className="hero-section d-none d-md-grid" >
					<BannerHomeSpringCampaign headers={headers} />
					<SecondaryCarouselHeroBanner />
				</div>
				<div className="d-block container d-md-none"
					style={{marginTop: 20}}>
					<BannerHomeSpringCampaign headers={headers}/>
				</div>
				<div className="container d-md-none d-flex align-items-center justify-content-center my-2"
					style={{height: 40}}>
					{reviews ?
						<GoogleRbox reviews={reviews}/> : null}
				</div>
				<div style={{overflowX: "auto"}}
					className="d-md-none col-12">
					<SecondaryCarouselHeroBanner />
				</div>

				<div className="container mt-2 px-0 pb-md-5">
					<UspSection isNewUsp/>
				</div>

				<div className="container mt-5">
					<BikeExplorerTabs />
				</div>
				<HomeCatBoxes hideTopModels />
				{process.env.NEXT_PUBLIC_ENVIRONMENT !== "production" ? <ShowroomHomepageSection /> : null}
				<HowItWorks blockTilesHowItWorks={dataHowItWorks}
					hasOptionalText
					className="pt-0 pb-5 pt-md-5 mt-3 mt-md-5" />
				<FindPerfectBike/>
				<LogoGallery isSlider
					brands={brandImages}/>
				<ServiceCenters/>
				{reviews &&
                <GoogleReviews reviews={reviews}/>}
				<InsuranceByCalingo/>
				<BlogsComponent posts={posts}/>
				{locale === "de" && <DownloadableContent/>}
				<InstagramSection/>
				<HomeTextComponent />
				<Footer/>
			</div>
		</>
	)
}

Home.propTypes = {
	reviews: PropTypes.any.isRequired,
	posts: PropTypes.array.isRequired
}

export async function getStaticProps({locale}) {
	const posts = await fetchPosts(true, locale, 3)
	const pageData = await getPageData(locale)
	const brandWidgets = pageData?.strapiContent?.data?.premium_brand_widgets
	const allPremiumBrandsGoogleReviews = brandWidgets.reduce((acc, item) => {
		if (item?.attributes?.googleReviews && Array.isArray(item?.attributes?.googleReviews)) {
			return acc.concat(item?.attributes?.googleReviews)
		}

		return acc
	}, []) || []

	return {
		props: {
			...pageData,
			allPremiumBrandsGoogleReviews,
			posts,
			...(await serverSideTranslations(locale, ["common", "homePage", "homeDetails", "productDescrPage", "search", "filters", "sponsorship", "insurance", "downloadableAssets"], nextI18NextConfig))
		}
	}
}

export default Home

import React, {useContext, useState} from "react"
import Button from "../reusable/components/Button"
import RichText from "../reusable/render/RichText"
import {gaCategories, gaEvents} from "../../config/googleAnalytics/events"
import AnalyticsContext from "../../context/AnalyticsContext"
import {Slides} from "../reusable/components/Slides"
import Image from "next/image"
import LayoutContext from "../../context/LayoutContext"

type Props = {
	headers?: any;
}

const BannerHomeSpringCampaign: React.FC<Props> = ({headers}) => {
	const [loading, setLoading] = useState(false)
	const onBtnClick = () => {
		setLoading(true)
	}

	const {reactGA} = useContext(AnalyticsContext)

	const headersChecked = headers
	const {isMobile} = useContext(LayoutContext)
	return (
		<div style={{height: "362px"
		}}
		className="home-banner-slider home-banner-width">
			{headersChecked && <Slides isHomePageBannerArrows
				customArrowClasses="mb-4"
				dotsPositionY={-20}
				dots={isMobile}
				leftArrowsPositionX={75.1}
				arrowsPositionY={0}
				autoplay
				arrows={!isMobile}
				arrowColor="white">
				{headersChecked?.filter(tile => process.env.NEXT_PUBLIC_ENVIRONMENT === "production" && tile?.title?.content !== "Bereit in die Pedalen zu treten?").map((header:any, i:number) => {
					const image = `${process.env.NEXT_PUBLIC_STRAPI_URL}${header?.image?.data[0].attributes.url}`
					const imageMobile = `${process.env.NEXT_PUBLIC_STRAPI_URL}${header?.imageMobile?.data[0].attributes.url}`
					const title = header?.title?.nodeType ? [header?.title] : null
					const description = header?.description?.nodeType ? [header?.description] : null
					const buttonLabel = header?.cta.label
					const buttonLink = header?.cta.href
					return <section key={i}
						className="home-banner mb-md-0 position-relative">
						<Image className="d-none d-md-block"
							src={image}
							layout={"fill"}
							objectFit={"cover"}/>
						<Image className="d-md-none"
							src={imageMobile ? imageMobile : image}
							layout={"fill"}
							objectFit={"cover"}/>
						<div className={"gradient-black-transparent"}/>
						<div className={"container text-white position-relative"}>
							<div className={"row pb-5 pb-md-0 home-banner-height align-items-end  justify-content-start"}>
								<div style={{padding: "24px"}}
									className={"col-12"}>
									<div className={"text-white text-center text-md-start"}>
										<div className={"mb-0"}>
											<span className={"d-inline-block rounded"}>
												{title && <RichText className="mb-0"
													content={title}
													strapiData
													isMobile={isMobile}
													isHomePage/>}
												{description && <RichText className="mb-0"
													content={description}
													strapiData
													isMobile={isMobile}
													isHomePage/>}
											</span>
										</div>
									</div>
									<div style={{marginTop: "16px"}}
										className={"row justify-content-md-start justify-content-center "}>
										<div className={" col-9 col-md-6 col-lg-auto"}>
											<div className={"d-grid "}>
												<Button label={buttonLabel}
													textColor="#212529"
													color="#000"
													bgColor="#FEC04D"
													outline={false}
													paddingY="3"
													href={buttonLink}
													className={"shadow"}
													isLoading={loading}
													onClick={() => {
														onBtnClick()
														reactGA?.event({
															category: gaCategories.homePage,
															action: gaEvents.letsGoButton,
															label: gaEvents.letsGoButton,
															nonInteraction: false
														})
													}}/>
											</div>
										</div>

									</div>
								</div>
							</div>
						</div>
					</section>
				})}
			</Slides>}
		</div>
	)
}

export default BannerHomeSpringCampaign
